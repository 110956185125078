<template>
  <div class="container-fluid">
    <div class="calendar-app">
      <div class="row">
          <div class="col-12">
            <avatar-calendar ref="vCalendar" :attributes="attributes" @onPageChange="onPageChange" @changeDateTime="changeDateTime" :is-month="false"></avatar-calendar>
          </div>
      </div>
      <div style="position: absolute; top: 0; right: 0; background-color: #ffffff; height: 100%; z-index: 2;">
        <transition name="bounce">
          <div v-show="eventId != null" style="width: 350px;">
            <div style="float: right; cursor: pointer;" @click="eventId = null">
              <i class="fas fa-times" style="color: red; font-size: medium; margin-top: 8px; margin-right: 5px;"></i>
            </div>
            <attendance-list-dialog ref="attendanceListDialog" :isOutSideMeeting="true" @goMeeting="goMeeting"></attendance-list-dialog>
          </div>
        </transition>
      </div>
    </div>
    <confirm-modal
      ref="confirmModal"
      :content="confirmModalContent"
      @onConfirm="onConfirm()"
    ></confirm-modal>
    <!-- Event list modal -->
    <b-modal
      v-model="showEventListModal"
      centered
      :hide-header="true"
      :hide-footer="true"
      id="modal-center"
      style="padding: 0;"
    >
      <div class="modal-header">
        <h5 class="modal-title">{{ $t('event.ongoingEvents') }} <br /> {{ dateSelected }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="container p-0 mt-2 mb-2">
        <div v-for="(eventSelected, index) in eventListSelected" :key="eventSelected + index">
          <div class="event-item" style="display: flex; justify-content: space-between; margin: 5px 0 5px 0; padding: 10px 15px 10px 15px; cursor: pointer;" @click="selectEvent(eventSelected)">
            <div>
              <span style="align-self: center;">
                {{ eventSelected.popover ? eventSelected.popover.label : '' }}
              </span>
            </div>
            <div style="cursor: pointer; align-self: center;" @click="selectEvent(eventSelected)">
              <i class="fas fa-info-circle" style="color: green; font-size: 20px;"></i>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateUtils from "utils/dateUtils";
import moment from "moment";
import AvatarCalendar from 'Components/AvatarCalendar'
import ConfirmModal from "Components/ConfirmModal";
import AttendanceListDialog from "Components/AttendanceListDialog";
import functionUtils from 'utils/functionUtils'
export default {
  components: {
    confirmModal: ConfirmModal,
    avatarCalendar: AvatarCalendar,
    attendanceListDialog: AttendanceListDialog,
  },
  data: function() {
    return {
      confirmModalContent: "meeting.confirmChooseMeeting",
      eventId: null,
      weekDaysByWeekNumber: [],
      attributes: [],
      eventTitle: null,
      showEventListModal: false,
      eventListSelected: [],
      dateSelected: null
    };
  },
  computed: {
    ...mapGetters(["GET_EVENT_WITH_DATE_DATA"]),
  },
  watch: {
    GET_EVENT_WITH_DATE_DATA: function() {
      this.calendarEvents = [];
      let events = this.GET_EVENT_WITH_DATE_DATA;
      this.attributes = [
        {
          key: 'today',
          highlight: true,
          dates: new Date()
        }
      ]
      for (let i = 0, size = events.length; i < size; i++) {
        let calendarEventObj = {
          key: events[i].id,
          dot: 'red',
          popover: {
            label: events[i].name
          },
          highlight: true,
          dates: [
            {
              start: dateUtils.formatDate(events[i].start_datetime, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, "YYYY-MM-DD HH:mm:ss"),
              end: dateUtils.formatDate(events[i].end_datetime, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, "YYYY-MM-DD HH:mm:ss"),
            }
          ]
        };
        this.attributes.push(calendarEventObj);
      }
      this.$refs.vCalendar.resetCount()
    },
  },
  methods: {
    /**
     * Select event
     */
    selectEvent: function (event) {
      this.showEventListModal = false
      this.eventId = event.key;
      this.eventTitle = event.label ? event.label : event.popover.label
      this.$refs.attendanceListDialog.getAttendeeSpeakerList(this.eventId)
    },
    // Go meeting
    goMeeting: function () {
      this.$refs.confirmModal.onShowModalConfirm(this.eventTitle)
    },
    /**
     * Fetch data event
     */
    fetchData: function (date) {
      let filter = {
        params: {
          calendarType: 'year',
          month: date.month,
          year: date.year
        }
      }
      this.GET_EVENT_WITH_DATE(filter)
    },
    /**
     * On page change
     */
    onPageChange : function (date) {
      this.fetchData(date)
    },
    /**
     * Change date time
     */
    changeDateTime: function (date) {
      if (!functionUtils.isNull(date.attributes) && date.attributes.length > 0 && date.attributes[0].key != 'today') {
        if (date.attributes && date.attributes.length > 1) {
          this.eventListSelected = date.attributes.filter((element) => {
            return !functionUtils.isNull(element.popover)
          })
          this.dateSelected = date.ariaLabel
          this.showEventListModal = true
        } else {
          this.dateSelected = null
          this.eventListSelected = null
          this.showEventListModal = false
          this.eventId = date.attributes[0].key;
          this.eventTitle = date.popovers[0].label
          this.$refs.attendanceListDialog.getAttendeeSpeakerList(this.eventId)
        }
      } else {
        this.showEventListModal = false
        this.eventSelected = null
        this.eventListSelected = null
        this.eventId = null
      }
    },
    /**
     * On confirm
     */
    onConfirm: function() {
      this.$store.state.app.inAMeeting = true;
      this.$router.push({
        name: "information.title",
      });
      sessionStorage.setItem("event_id", this.eventId);
    },
    getWeekDaysByWeekNumber(weeknumber) {
      var dateformat = "YYYY/MM/DD";
      var date = moment().isoWeek(weeknumber || 1).startOf("week"),
        weeklength = 7,
        result = [];
      while (weeklength--) {
        let object = {
          day: date.format("dddd"),
          date: date.format(dateformat),
        };
        result.push(object);
        date.add(1, "day");
      }
      return result;
    },
    ...mapActions(["GET_EVENT_WITH_DATE"]),
  }
};
</script>
<style lang="scss" scoped>
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "../../style/color.scss";
.calendar-app {
  position: relative;
  padding: 5px 0px 15px 0px;
  font-size: 14px;
}
.calendar-app-top {
  margin: 0 0 3em;
}
.calendar-app-calendar {
  margin: 0 auto;
  margin-top: 15px;
  width: 100%;
}
/deep/ .fc-button-primary {
  background-color: $primary !important;
  border: none;
}
/deep/ .fc-today-button {
  background-color: $light-primary !important;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
/deep/ .modal-body {
  padding: 0;
}
.event-item:hover {
  background-color: #e5e5e5;
}
</style>
